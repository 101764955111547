import React from 'react';

const suggestedPrompts = [
  "Hangi firmalarla ilgili bilgi alabilirim?",
  "Akbank'ın son çeyrek net karı nedir?",
  "Garanti'nin 2019-2023 net karlarını görsellerle analiz edebilir misin?",
  "Garanti'nin son hisse değeri nedir?"
];

function Welcome({ onSendMessage, setIsCompanyListOpen }) {
  const handlePromptClick = (prompt) => {
    if (prompt === "Hangi firmalarla ilgili bilgi alabilirim?") {
      onSendMessage(prompt);
    } else {
      onSendMessage(prompt);
    }
  };

  return (
    <div className="welcome-container">
      <h2>Yatırımcı İlişkileri Danışmanınıza Hoşgeldiniz</h2>
      <p>Aşağıdaki Örneklerle Sohbete Başlayabilirsiniz:</p>
      <div className="suggested-prompts">
        {suggestedPrompts.map((prompt, index) => (
          <button 
            key={index} 
            onClick={() => handlePromptClick(prompt)}
          >
            {prompt}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Welcome;