import React from 'react';
import { Moon, Sun, Building2 } from 'lucide-react';
import logo from '../images/logoFull.png';
import CompanyList from './CompanyList';
import Modal from './Modal';

function Header({ 
  darkMode, 
  toggleDarkMode, 
  isCompanyListOpen, 
  setIsCompanyListOpen,
  onBackToHome 
}) {
  return (
    <header className="app-header">
      <div className="flex items-center gap-4">
        <div 
          className="logo-container cursor-pointer hover:opacity-80 transition-opacity"
          onClick={onBackToHome}
        >
          <img src={logo} alt="FinSight Logo" className="app-logo" />
        </div>
      </div>
      <button 
        className="dark-mode-toggle flex items-center justify-center" 
        onClick={toggleDarkMode}
        title={darkMode ? "Açık Tema" : "Koyu Tema"}
      >
        {darkMode ? <Sun size={24} /> : <Moon size={24} />}
      </button>
      
      <Modal isOpen={isCompanyListOpen} onClose={() => setIsCompanyListOpen(false)}>
        <CompanyList 
          isOpen={isCompanyListOpen} 
          onClose={() => setIsCompanyListOpen(false)} 
        />
      </Modal>
    </header>
  );
}

export default Header;