// App.js
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import CompanyList from './components/CompanyList';
import ChatWindow from './components/ChatWindow';
import ChatForm from './components/ChatForm';
import Header from './components/Header';
import Welcome from './components/Welcome';
import Modal from './components/Modal';
import Home from './components/home/Home';
import './styles.css';

function App() {
  const [messages, setMessages] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [isCompanyListOpen, setIsCompanyListOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('userId')) {
      const userId = 'user-' + Math.random().toString(36).substr(2, 9);
      localStorage.setItem('userId', userId);
    }
  }, []);

  const createNewThread = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/create-thread', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: localStorage.getItem('userId') })
      });

      if (!response.ok) {
        throw new Error('Failed to create new thread');
      }

      const { threadId: newThreadId } = await response.json();
      setThreadId(newThreadId);
      setMessages([]);
      return newThreadId;
    } catch (error) {
      console.error('Error creating new thread:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode');
  };

  const handleStartChat = async () => {
    setIsTransitioning(true);
    const newThreadId = await createNewThread();
    if (newThreadId) {
      setIsChatVisible(true);
      setThreadId(newThreadId);
    }
    setIsTransitioning(false);
  };

  const escapeMarkdown = (text) => {
    return text.replace(/`/g, '\\`');
  };

  const sendMessage = async (message) => {
    if (!threadId) {
      console.error('No thread ID available');
      return;
    }

    const newUserMessage = { sender: 'user', text: message };
    const newAssistantMessage = { sender: 'assistant', text: '', isTyping: true };
    setMessages(prevMessages => [...prevMessages, newUserMessage, newAssistantMessage]);

    try {
      const response = await fetch('/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message,
          userId: localStorage.getItem('userId'),
          threadId: threadId
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setMessages(prevMessages =>
            prevMessages.map((msg, index) =>
              index === prevMessages.length - 1 ? { ...msg, isTyping: false } : msg
            )
          );
          break;
        }

        buffer += decoder.decode(value, { stream: true });
        let boundary = buffer.indexOf('\n\n');

        while (boundary !== -1) {
          const event = buffer.slice(0, boundary);
          buffer = buffer.slice(boundary + 2);

          if (event.startsWith('data: ')) {
            const data = event.slice(6).trim();

            if (data === '[DONE]') {
              continue;
            }

            try {
              const parsed = JSON.parse(data);
              if (parsed.text) {
                const escapedText = escapeMarkdown(parsed.text);
                setMessages(prevMessages => {
                  const lastMessage = prevMessages[prevMessages.length - 1];
                  const updatedLastMessage = {
                    ...lastMessage,
                    text: (lastMessage.text || '') + escapedText,
                    isTyping: true,
                  };
                  return [...prevMessages.slice(0, -1), updatedLastMessage];
                });
              } else if (parsed.type === 'image') {
                setMessages(prevMessages => {
                  const lastMessage = prevMessages[prevMessages.length - 1];
                  if (lastMessage.sender === 'assistant') {
                    const updatedLastMessage = {
                      ...lastMessage,
                      imageData: parsed.data,
                      isTyping: false,
                    };
                    return [...prevMessages.slice(0, -1), updatedLastMessage];
                  } else {
                    const imageMessage = {
                      sender: 'assistant',
                      imageData: parsed.data,
                      isTyping: false,
                    };
                    return [...prevMessages, imageMessage];
                  }
                });
              }
            } catch (error) {
              console.error('Failed to parse event data:', data, error);
            }
          }

          boundary = buffer.indexOf('\n\n');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages(prevMessages => [
        ...prevMessages.slice(0, -1),
        { sender: 'assistant', text: 'Sorry, there was an error processing your request.', isTyping: false }
      ]);
    }
  };

  return (
    <div className={`app-container ${darkMode ? 'dark-mode' : ''}`}>
      <AnimatePresence mode="wait">
        {!isChatVisible && !isTransitioning && (
            <button
              className="chat-widget-button"
              onClick={handleStartChat}
            >
              <div className="widget-icon">
                💬
              </div>
            </button>
          )}
        {isLoading ? (
          <motion.div 
            className="loading-screen"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ 
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96]
            }}
          >
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="loading-content"
            >
              <motion.div 
                className="loading-icon"
                animate={{ 
                  rotate: 360,
                  scale: [1, 1.1, 1],
                  opacity: [0.5, 1, 0.5]
                }}
                transition={{ 
                  rotate: { duration: 1.5, repeat: Infinity, ease: "linear" },
                  scale: { duration: 2, repeat: Infinity },
                  opacity: { duration: 2, repeat: Infinity }
                }}
              />
              <motion.h2 
                className="loading-title"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                Yükleniyor...
              </motion.h2>
              <motion.p 
                className="loading-subtitle"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                Lütfen bekleyiniz...
              </motion.p>
            </motion.div>
          </motion.div>
        ) : !isChatVisible ? (
          <motion.div
            key="home"
            initial={{ opacity: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ 
              duration: 0.3,
              ease: [0.43, 0.13, 0.23, 0.96]
            }}
          >
            <Home 
              darkMode={darkMode} 
              toggleDarkMode={toggleDarkMode}
              onStartChat={handleStartChat}
            />
          </motion.div>
        ) : (
          <motion.div
            key="chat"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="chat-container"
          >
            <Header 
              darkMode={darkMode} 
              toggleDarkMode={toggleDarkMode} 
              isCompanyListOpen={isCompanyListOpen}
              setIsCompanyListOpen={setIsCompanyListOpen}
              onBackToHome={() => setIsChatVisible(false)}
            />
            <main className="main-content">
              {messages.length === 0 ? (
                <Welcome 
                  onSendMessage={sendMessage} 
                  setIsCompanyListOpen={setIsCompanyListOpen}
                />
              ) : (
                <ChatWindow messages={messages} openModal={setModalImage} />
              )}
              <ChatForm onSendMessage={sendMessage} onClearChat={createNewThread} />
            </main>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default App;