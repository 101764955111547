import React, { useEffect, useState } from 'react';
import { motion, useScroll, useTransform, AnimatePresence, useMotionValue } from 'framer-motion';
import { 
  BarChart2, Shield, Zap, MessageCircle, Menu, X, ArrowRight, 
  Moon, Sun, Mail, Phone, MapPin
} from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const chartData = [
  { name: 'Jan', value: 4000 }, { name: 'Feb', value: 3000 },
  { name: 'Mar', value: 5000 }, { name: 'Apr', value: 2780 },
  { name: 'May', value: 1890 }, { name: 'Jun', value: 2390 },
  { name: 'Jul', value: 3490 }
];

const features = [
  {
    id: 'analytics',
    title: 'Advanced Analytics',
    subtitle: 'Real-time financial insights and analysis',
    Icon: BarChart2,
    color: 'from-blue-500 to-cyan-500'
  },
  {
    id: 'security',
    title: 'Enterprise Security',
    subtitle: 'Bank-grade protection for your data',
    Icon: Shield,
    color: 'from-purple-500 to-pink-500'
  },
  {
    id: 'speed',
    title: 'Lightning Fast',
    subtitle: 'Instant responses and updates',
    Icon: Zap,
    color: 'from-amber-500 to-red-500'
  }
];

const contactInfo = [
  {
    Icon: Mail,
    title: 'Email',
    content: 'contact@finsight.com',
    link: 'mailto:contact@finsight.com'
  },
  {
    Icon: Phone,
    title: 'Phone',
    content: '+1 (555) 123-4567',
    link: 'tel:+15551234567'
  },
  {
    Icon: MapPin,
    title: 'Address',
    content: '123 Finance Street, Business District',
    link: '#'
  }
];

// Mouse position context
const MouseContext = React.createContext({ x: 0, y: 0 });

// Particle Component
const Particle = ({ index, mouseX, mouseY }) => {
  const x = useTransform(
    mouseX,
    [-1000, 1000],
    [-50 * (index % 3 + 1), 50 * (index % 3 + 1)]
  );
  const y = useTransform(
    mouseY,
    [-1000, 1000],
    [-50 * (index % 3 + 1), 50 * (index % 3 + 1)]
  );

  return (
    <motion.div
      className="absolute rounded-full bg-gradient-to-r"
      style={{
        x,
        y,
        width: Math.random() * 200 + 50,
        height: Math.random() * 200 + 50,
        background: `radial-gradient(circle at center, 
          ${index % 2 === 0 ? 'rgba(96,165,250,0.1)' : 'rgba(167,139,250,0.1)'}, 
          transparent)`,
        left: `${(index % 5) * 20}%`,
        top: `${Math.floor(index / 5) * 20}%`,
      }}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ 
        opacity: [0.1, 0.3, 0.1],
        scale: [1, 1.2, 1],
        rotate: [0, 180, 360]
      }}
      transition={{
        duration: 10 + index * 2,
        repeat: Infinity,
        ease: "linear"
      }}
    />
  );
};

// Interactive Cursor Effect
const CursorEffect = () => {
  const { x, y } = React.useContext(MouseContext);
  
  return (
    <motion.div
      className="fixed w-40 h-40 pointer-events-none z-50"
      style={{
        x: x - 80,
        y: y - 80,
        background: "radial-gradient(circle at center, rgba(96,165,250,0.15), transparent 70%)",
        borderRadius: "50%",
        filter: "blur(10px)"
      }}
    />
  );
};

// Enhanced Parallax Background Component
const ParallaxBackground = () => {
  const [scrollY, setScrollY] = useState(0);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);
  
  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
      mouseX.set(e.clientX - window.innerWidth / 2);
      mouseY.set(e.clientY - window.innerHeight / 2);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('mousemove', handleMouseMove, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mouseX, mouseY]);

  return (
    <MouseContext.Provider value={mousePosition}>
      <div className="fixed inset-0 pointer-events-none overflow-hidden">
        <motion.div 
          className="absolute inset-0 bg-gradient-to-br from-blue-900/30 via-purple-900/30 to-pink-900/30"
          style={{ transform: `translateY(${scrollY * 0.5}px)` }}
        />
        
        {/* Particles Layer */}
        <div className="absolute inset-0">
          {Array.from({ length: 15 }).map((_, i) => (
            <Particle key={i} index={i} mouseX={mouseX} mouseY={mouseY} />
          ))}
        </div>

        {/* Mouse Trail Effect */}
        <motion.div
          className="absolute inset-0"
          style={{
            background: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, 
              rgba(96,165,250,0.1) 0%, transparent 20%)`,
            transition: 'all 0.15s ease'
          }}
        />

        {/* Interactive Grid */}
        <div className="absolute inset-0 grid grid-cols-8 grid-rows-8">
          {Array.from({ length: 64 }).map((_, i) => (
            <motion.div
              key={i}
              className="border-[0.5px] border-blue-500/5"
              whileHover={{ 
                backgroundColor: "rgba(96,165,250,0.1)",
                transition: { duration: 0.3 }
              }}
            />
          ))}
        </div>

        <CursorEffect />
      </div>
    </MouseContext.Provider>
  );
};

// Navbar Component
const Navbar = ({ darkMode, toggleDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { scrollYProgress } = useScroll();
  const navOpacity = useTransform(scrollYProgress, [0, 0.2], [0.5, 1]);
  const navBlur = useTransform(scrollYProgress, [0, 0.2], [0, 12]);

  const navItems = [
    { href: '#features', label: 'Features' },
    { href: '#analytics', label: 'Analytics' },
    { href: '#about', label: 'About' },
    { href: '#contact', label: 'Contact' },
  ];

  return (
    <motion.nav 
      className="fixed w-full z-50 border-b border-white/10"
      style={{ 
        opacity: navOpacity,
        backdropFilter: `blur(${navBlur}px)`,
        backgroundColor: 'rgba(17, 24, 39, 0.8)'
      }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <motion.div 
            className="flex-shrink-0"
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <span className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
              FinSight
            </span>
          </motion.div>
          
          <div className="hidden md:block">
            <motion.div 
              className="ml-10 flex items-center space-x-8"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              {navItems.map((item) => (
                <a 
                  key={item.href}
                  href={item.href}
                  className="text-gray-300 hover:text-white transition-all hover:scale-105"
                >
                  {item.label}
                </a>
              ))}
              <motion.button 
                onClick={toggleDarkMode}
                className="text-gray-300 hover:text-white transition-all p-2 rounded-full hover:bg-white/10"
                whileHover={{ rotate: 180 }}
                transition={{ duration: 0.3 }}
              >
                {darkMode ? <Sun size={20} /> : <Moon size={20} />}
              </motion.button>
            </motion.div>
          </div>
          
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-300 hover:text-white p-2 transition-transform transform hover:scale-110"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div 
            className="md:hidden"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-gray-900/95 backdrop-blur-lg">
              {navItems.map((item) => (
                <a
                  key={item.href}
                  href={item.href}
                  className="block px-3 py-2 text-gray-300 hover:text-white transition-transform hover:translate-x-2"
                  onClick={() => setIsOpen(false)}
                >
                  {item.label}
                </a>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
};

// Animated Section Component
const AnimatedSection = ({ children, className = "", id = "" }) => {
  return (
    <motion.section
      id={id}
      className={`py-20 px-4 relative ${className}`}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "-100px" }}
      transition={{ duration: 0.6 }}
    >
      {children}
    </motion.section>
  );
};

const Home = ({ darkMode, toggleDarkMode, onStartChat }) => {
  const { scrollYProgress } = useScroll();
  const scaleProgress = useTransform(scrollYProgress, [0, 1], [1, 1.5]);
  const opacityProgress = useTransform(scrollYProgress, [0, 0.5, 1], [1, 0.5, 0]);

  return (
    <div className="min-h-screen bg-gray-900 text-white overflow-hidden">
      <ParallaxBackground />
      <Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      
      {/* Hero Section */}
      <motion.section 
        className="relative min-h-screen flex items-center justify-center px-4"
        style={{ scale: scaleProgress, opacity: opacityProgress }}
      >
        <div className="text-center space-y-8 relative z-10">
          <motion.h1 
            className="text-7xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, type: "spring" }}
          >
            FinSight
          </motion.h1>
          
          <motion.p
            className="text-2xl text-gray-300 max-w-2xl mx-auto"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2, type: "spring" }}
          >
            Transform Your Financial Data into Actionable Insights with AI-Powered Analytics
          </motion.p>
          
          <motion.button
            onClick={onStartChat}
            className="group px-8 py-4 bg-blue-600 rounded-full text-lg font-semibold hover:bg-blue-700 
                     transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/25 flex items-center justify-center mx-auto gap-2"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Get Started 
            <motion.span
              initial={{ x: 0 }}
              animate={{ x: [0, 5, 0] }}
              transition={{ duration: 1.5, repeat: Infinity }}
            >
              <ArrowRight className="inline" />
            </motion.span>
          </motion.button>
        </div>
      </motion.section>

      {/* Features Section */}
      <AnimatedSection id="features">
        <div className="max-w-7xl mx-auto">
          <motion.h2 
            className="text-5xl font-bold text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            Why Choose FinSight?
          </motion.h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.id}
                className="relative group"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
              >
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r opacity-0 group-hover:opacity-100 blur-xl transition-opacity duration-500"
                  style={{ background: `linear-gradient(to right, ${feature.color})` }}
                />
                <motion.div
                  className="bg-white/5 backdrop-blur-sm rounded-2xl p-8 relative z-10
                           border border-white/10 hover:border-white/20 transition-all duration-500"
                  whileHover={{ scale: 1.05, rotate: 2 }}
                >
                  <feature.Icon className={`w-12 h-12 mb-4 bg-gradient-to-r ${feature.color} bg-clip-text text-transparent`} />
                  <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-400">{feature.subtitle}</p>
                </motion.div>
              </motion.div>
            ))}
          </div>
        </div>
      </AnimatedSection>

      {/* Analytics Demo Section */}
      <AnimatedSection id="analytics" className="bg-white/5">
        <div className="max-w-7xl mx-auto">
          <motion.h2
            className="text-4xl font-bold text-center mb-12"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            Real-Time Analytics
          </motion.h2>
          
          <motion.div 
            className="bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          >
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
                <XAxis dataKey="name" stroke="#94a3b8" />
                <YAxis stroke="#94a3b8" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: 'rgba(15, 23, 42, 0.9)',
                    borderRadius: '8px',
                    borderColor: 'rgba(255,255,255,0.1)',
                    color: '#fff'
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#60a5fa"
                  strokeWidth={3}
                  dot={{ fill: '#60a5fa', strokeWidth: 2 }}
                  activeDot={{ r: 8, fill: '#60a5fa' }}
                />
              </LineChart>
            </ResponsiveContainer>
          </motion.div>
        </div>
      </AnimatedSection>

      {/* About Section */}
      <AnimatedSection id="about">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="grid md:grid-cols-2 gap-12 items-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div className="space-y-6">
              <h2 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
                About FinSight
              </h2>
              <p className="text-gray-300 text-lg leading-relaxed">
                FinSight is your AI-powered financial analysis companion. We combine cutting-edge artificial intelligence 
                with comprehensive financial data to provide you with actionable insights and real-time analytics.
              </p>
              <p className="text-gray-300 text-lg leading-relaxed">
                Our platform helps you make informed decisions by analyzing complex financial data and presenting it 
                in an easy-to-understand format, saving you time and improving accuracy.
              </p>
              <motion.button
                className="px-6 py-3 bg-blue-600 rounded-lg text-white font-semibold hover:bg-blue-700 
                         transition-all flex items-center gap-2"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Learn More
                <ArrowRight size={20} />
              </motion.button>
            </div>
            <div className="relative">
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500 opacity-20 blur-3xl"
                animate={{
                  scale: [1, 1.2, 1],
                  rotate: [0, 180, 0],
                }}
                transition={{
                  duration: 20,
                  repeat: Infinity,
                  ease: "linear"
                }}
              />
              <motion.div
                className="relative bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10"
                whileHover={{ scale: 1.02 }}
              >
                {/* Placeholder for about section visual content */}
                <div className="h-80 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20 
                             flex items-center justify-center">
                  <span className="text-4xl">✨</span>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </AnimatedSection>

      {/* Contact Section */}
      <AnimatedSection id="contact" className="bg-white/5">
        <div className="max-w-7xl mx-auto">
          <motion.div
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-4">Get in Touch</h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Have questions about FinSight? We're here to help! Send us a message and we'll get back to you as soon as possible.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8 mb-16">
            {contactInfo.map((info, index) => (
              <motion.a
                key={info.title}
                href={info.link}
                className="group bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10 
                         hover:border-white/20 transition-all text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ scale: 1.05 }}
              >
                <motion.div
                  className="w-12 h-12 rounded-full bg-blue-500/10 flex items-center justify-center mx-auto mb-4
                           group-hover:bg-blue-500/20 transition-all"
                  whileHover={{ rotate: 360 }}
                  transition={{ duration: 0.5 }}
                >
                  <info.Icon className="w-6 h-6 text-blue-400" />
                </motion.div>
                <h3 className="text-lg font-semibold mb-2">{info.title}</h3>
                <p className="text-gray-400">{info.content}</p>
              </motion.a>
            ))}
          </div>

          <motion.div
            className="max-w-3xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <form className="bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10">
              <div className="grid md:grid-cols-2 gap-6 mb-6">
                <div className="space-y-2">
                  <label className="text-sm text-gray-300">Name</label>
                  <input
                    type="text"
                    className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg 
                             focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                    placeholder="Your name"
                  />
                </div>
                <div className="space-y-2">
                  <label className="text-sm text-gray-300">Email</label>
                  <input
                    type="email"
                    className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg 
                             focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                    placeholder="your@email.com"
                  />
                </div>
              </div>
              
              <div className="space-y-2 mb-6">
                <label className="text-sm text-gray-300">Message</label>
                <textarea
                  rows={4}
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg 
                           focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all resize-none"
                  placeholder="Your message"
                />
              </div>

              <motion.button
                type="submit"
                className="w-full px-6 py-4 bg-blue-600 rounded-lg text-white font-semibold 
                         hover:bg-blue-700 transition-all flex items-center justify-center gap-2"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Send Message
                <motion.span
                  animate={{ x: [0, 5, 0] }}
                  transition={{ duration: 1.5, repeat: Infinity }}
                >
                  <ArrowRight size={20} />
                </motion.span>
              </motion.button>
            </form>
          </motion.div>
        </div>
      </AnimatedSection>

      {/* Chat Widget */}
      <motion.button
        className="fixed bottom-8 right-8 w-16 h-16 bg-blue-600 rounded-full flex items-center justify-center 
                 text-white shadow-lg hover:bg-blue-700 transition-all z-50"
        onClick={onStartChat}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <MessageCircle size={24} />
      </motion.button>
    </div>
  );
};

export default Home;